import ImageTool from '@editorjs/image';
import api from "@/utils/api";

export default class GalleryTool extends ImageTool {

  constructor({ data, config, api, readOnly, block }) {
    super({ data, config, api, readOnly, block });
  }

  appendDefault(url) {
    this.onUpload({
      success: 1,
      file: {
        url: url,
      }
    });
  }

  // 기존에 파일 업로드 자동으로 오픈하는 block 의 rendering 콜백
  appendCallback() {

  }

  render() {
    const wrapper = document.createElement('div');
    const defaultRender = this.ui.render(this.data);

    if (!this.data.file.url && this.config.endpoints.prefetch) {
      const galleryContainer = document.createElement('div');
      galleryContainer.className = "cdx-button gallery-container";

      if (this.config.endpoints.prefetch === "demo") {
        const demoUrls = ["https://picsum.photos/id/110/640/320", "https://picsum.photos/id/42/640/320"];
        Array.from(demoUrls).forEach((image_url) => {
          const image = document.createElement('img');
          image.src = image_url;
          image.addEventListener('click', () => {
            return this.appendDefault(image_url)
          });
          galleryContainer.appendChild(image);
        });
      } else {
        api.post(this.config.endpoints.prefetch).then((response) => {
          Array.from(response.data).forEach((image_url) => {
            const image = document.createElement('img');
            image.src = image_url;
            image.addEventListener('click', () => {
              return this.appendDefault(image_url)
            });
            galleryContainer.appendChild(image);
          });
        });
      }

      defaultRender.appendChild(galleryContainer);
    }

    wrapper.appendChild(defaultRender);

    return wrapper;
  }

}