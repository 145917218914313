import Rails from '@rails/ujs';
import "@hotwired/turbo-rails"
import * as ActiveStorage from "@rails/activestorage"
import '../controllers'
import 'alpine-turbo-drive-adapter'
import 'alpinejs'
import "./zoom.min"

Rails.start();
ActiveStorage.start();

// https://turbo.hotwired.dev/handbook/streams#custom-actions
window.Turbo.StreamActions.scroll_to = function () {
    const target = this.targetElements[0];
    target.scrollIntoView({behavior: "auto", block: "start"});
};
